import ky from "ky";
import React, { useEffect, useState } from "react";
import * as t from "io-ts";

const ingestionStatusResponse = t.record(
  t.string,
  t.union([
    t.undefined,
    t.union([
      t.type({
        status: t.literal("Started"),
      }),
      t.type({
        status: t.literal("Finished"),
        results: t.unknown,
      }),
      t.type({
        status: t.literal("Failed"),
        errorMessage: t.string,
      }),
    ]),
  ])
);

export type EditPageProps = {
  jwt: string;
  isStatsLoader: boolean;
  unmodalize: () => void;
};

async function onSubmit(
  jwt: string,
  geographyVintage: string,
  acsYear: string,
  statistic: string,
  overwriteExisting: boolean,
  setMessage: React.Dispatch<React.SetStateAction<string>>
) {
  const startTime = Date.now();
  try {
    const geo = parseInt(geographyVintage, 10);
    if (!(geo >= 2000 && geo <= 2040)) {
      throw new Error("Try a real geo year");
    }
    const acs = parseInt(acsYear, 10);
    if (!(acs >= 2000 && acs <= 2049)) {
      throw new Error("Try a real ACS vintage");
    }
    if (statistic.length <= 4) {
      throw new Error("Try a real census statistic");
    }
    setMessage("Ingesting statistic...");
    const messageId = await ky
      .post("/api/edit/NewIngest", {
        headers: { Authorization: jwt },
        json: {
          geographyVintage: geo,
          acsYear: acs,
          statistic,
          overwriteExisting,
        },
      })
      .json();
    if (typeof messageId !== "string") {
      console.error(`typeof messageId !== "string"`);
      throw new Error(`typeof messageId !== "string"`);
    }
    setMessage(`Got messageId: ${messageId}`);
    console.log(messageId);
    let didSucceed = false;
    let results: unknown;
    for (let i = 0; i < 50; i++) {
      const ingestionStatus = await ky
        .post("/api/edit/CheckIngestionStatus", {
          headers: { Authorization: jwt },
          json: {
            messageId,
          },
        })
        .json();
      if (!ingestionStatusResponse.is(ingestionStatus)) {
        console.error(`!ingestionStatusResponse.is(ingestionStatus)`);
        console.log("Response:");
        console.log(JSON.stringify(ingestionStatus));
        throw new Error(`!ingestionStatusResponse.is(ingestionStatus)`);
      }
      const status = ingestionStatus[messageId];
      if (status !== undefined) {
        if (status.status === "Started") {
          setMessage(`Started (request = ${messageId})`);
        } else if (status.status === "Finished") {
          didSucceed = true;
          results = status.results;
          break;
        } else if (status.status === "Failed") {
          throw new Error(status.errorMessage);
        } else {
          // Should be impossible;
          throw new Error(`Unknown status ${JSON.stringify(status)}`);
        }
      }
      await new Promise((resolve) => setTimeout(resolve, 3000));
    }
    if (!didSucceed) {
      throw new Error(`Timed out after 2.5 minutes`);
    }
    const finishTime = Date.now();
    console.log(`Success after ${finishTime - startTime} ms`);
    setMessage(JSON.stringify(results));
  } catch (e) {
    const errorTime = Date.now();
    console.log(`Error after ${errorTime - startTime} ms`);
    console.log(JSON.stringify(e));
    console.error(e);
    setMessage(`Ingestion request failed: ${JSON.stringify(e)}`);
  }
}

function EditPage(props: EditPageProps) {
  const { jwt, isStatsLoader, unmodalize } = props;
  const [message, setMessage] = useState("");
  const [ingestedStats, setIngestedStats] = useState("");

  const [geographyVintage, setGeographyVintage] = useState("2010");
  const [acsYear, setAcsYear] = useState("2014");
  const [statistic, setStatistic] = useState("");
  const [overwriteExisting, setOverwriteExisting] = useState(false);

  useEffect(() => {
    (async () => {
      const stats = await ky
        .post("/api/edit/ListStatistics", {
          headers: { Authorization: jwt },
          json: {},
        })
        .json();
      setIngestedStats(JSON.stringify(stats));
    })();
  });

  return isStatsLoader ? (
    <div className="MainPage">
      <div>
        <div>
          <button onClick={unmodalize}>Back To Map</button>
        </div>
        <div>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              onSubmit(
                jwt,
                geographyVintage,
                acsYear,
                statistic,
                overwriteExisting,
                setMessage
              );
            }}
          >
            <p>
              <label htmlFor="geography-vintage">Geography Vintage</label>
              <input
                type="text"
                id="geography-vintage"
                value={geographyVintage}
                onChange={(e) => {
                  setGeographyVintage(e.target.value);
                }}
              ></input>
            </p>
            <p>
              <label htmlFor="acs-year">ACS Year</label>
              <input
                type="text"
                id="acs-year"
                value={acsYear}
                onChange={(e) => {
                  setAcsYear(e.target.value);
                }}
              ></input>
            </p>
            <p>
              <label htmlFor="statistic">Statistic</label>
              <input
                type="text"
                id="statistic"
                value={statistic}
                onChange={(e) => setStatistic(e.target.value)}
              ></input>
            </p>
            <p>
              <label htmlFor="overwrite-existing">
                Overwrite Existing Data?
              </label>
              <input
                type="checkbox"
                id="overwrite-existing"
                checked={overwriteExisting}
                onChange={(e) => setOverwriteExisting(e.target.checked)}
              />
            </p>
            <button type="submit">Load Statistic</button>
          </form>
        </div>
        <div>{message}</div>
      </div>
      <div>{ingestedStats}</div>
    </div>
  ) : (
    <div className="MainPage">
      <div>
        How did you get here?
        <button onClick={unmodalize}>Back To Map</button>
      </div>
    </div>
  );
}

export default EditPage;
