import React, { useState, useEffect } from "react";
import { Layer, Source } from "react-map-gl";
import { eqSet } from "./utils-and-types";
import { GeoJSONDisplayInfo } from "./utils-and-types";

import ky from "ky";
import * as t from "io-ts";

const featureMap = t.record(
  t.string,
  t.type({
    type: t.literal("Feature"),
    properties: t.type({
      GEOID: t.string,
      // uuid: t.union([t.string, t.undefined]),
    }),
    geometry: t.type({
      type: t.union([t.literal("Polygon"), t.literal("MultiPolygon")]),
      coordinates: t.UnknownArray,
    }),
  })
);
type FeatureMap = t.TypeOf<typeof featureMap>;

const statsResult = t.record(t.string, t.number);
type StatsResult = t.TypeOf<typeof statsResult>;

const percentileResult = t.type({
  countyPercentiles: t.record(t.string, t.number),
  tractPercentiles: t.record(t.string, t.number),
});
type PercentileResult = t.TypeOf<typeof percentileResult>;

function areCensusStatisticsEqual(
  prevStatistic: CensusStatistic,
  nextStatistic: CensusStatistic
) {
  return (
    prevStatistic.acsYear === nextStatistic.acsYear &&
    prevStatistic.geographyVintage === nextStatistic.geographyVintage &&
    prevStatistic.statistic === nextStatistic.statistic
  );
}

function areEqual(
  prevProps: DataLayerProps,
  nextProps: DataLayerProps
): boolean {
  const prevGeoids = new Set<string>(prevProps.displayInfo.map((x) => x.geoId));
  const nextGeoids = new Set<string>(nextProps.displayInfo.map((x) => x.geoId));

  const prevUUIDs = new Set<string>(
    prevProps.displayInfo.map((x) => x.jsonUUID)
  );
  const nextUUIDs = new Set<string>(
    nextProps.displayInfo.map((x) => x.jsonUUID)
  );

  if (
    areCensusStatisticsEqual(
      prevProps.censusStatistic,
      nextProps.censusStatistic
    ) &&
    eqSet(prevGeoids, nextGeoids) &&
    eqSet(prevUUIDs, nextUUIDs)
  ) {
    // console.log("whoo skipped rerender");
    return true;
  } else {
    // console.log("bummer different features");
    // console.log(
    //   "statistics:",
    //   areCensusStatisticsEqual(
    //     prevProps.censusStatistic,
    //     nextProps.censusStatistic
    //   )
    // );
    // console.log("geoids:", eqSet(prevGeoids, nextGeoids));
    // console.log("uuids:", eqSet(prevUUIDs, nextUUIDs));
    return false;
  }
}

async function onlyCachedGet(myCache: Cache, url: string): Promise<unknown> {
  const cachedResponse = await myCache.match(url);

  if (cachedResponse === undefined || !cachedResponse.ok) {
    return null;
  }

  return cachedResponse.json();
}

async function cachedGet(myCache: Cache, url: string): Promise<unknown> {
  // console.log(`cachegetting ${url}`);
  let cachedData = await onlyCachedGet(myCache, url);
  if (cachedData !== null) {
    // console.log(`Data for ${url} was in cache`);
    return cachedData;
  }
  // console.log(`Cache miss for ${url}, fetching`);
  await myCache.add(url);
  return onlyCachedGet(myCache, url);
}

export type CensusStatistic = {
  geographyVintage: number;
  acsYear: number;
  statistic: string;
};
export type DataLayerProps = {
  jwt: string;
  displayInfo: GeoJSONDisplayInfo;
  censusStatistic: CensusStatistic;
};

function stringifyStatistic(stat: CensusStatistic): string {
  return `g${stat.geographyVintage}_a${stat.acsYear}_${stat.statistic}`;
}

const MAGMA_COLORS = [
  "#350f6a",
  "#5d177e",
  "#822581",
  "#a9327c",
  "#d0416f",
  "#ee5d5d",
  "#fb8861",
  "#feb57c",
  "#fde1a3",
];

function magmatize(
  percentiles: { [percentile: string]: number },
  colors: string[]
) {
  if (colors.length !== 9) {
    throw new Error("Write more code to handle this");
  }
  return colors.map((color, idx) => [
    percentiles[((idx + 1) * 10).toString()],
    color,
  ]);
}

function DataLayer(props: DataLayerProps) {
  const { jwt, displayInfo, censusStatistic } = props;
  const { acsYear, geographyVintage, statistic } = censusStatistic;

  const [loadingUUIDs, setLoadingUUIDs] = useState([] as string[]);
  const [boundaries, setBoundaries] = useState(
    {} as { [uuid: string]: FeatureMap }
  );

  const [loadingGeoIds, setLoadingGeoIds] = useState(
    {} as Partial<{ [statistic: string]: string[] }>
  );
  const [stats, setStats] = useState(
    {} as Partial<{ [statistic: string]: StatsResult }>
  );
  const [percentiles, setPercentiles] = useState(
    {} as Partial<{ [statistic: string]: PercentileResult }>
  );

  useEffect(() => {
    const statAsString = stringifyStatistic({
      geographyVintage,
      acsYear,
      statistic,
    });
    if (percentiles[statAsString] === undefined) {
      (async () => {
        console.log(`loading percentiles for ${statAsString}`);
        const fetchedPercentiles = await ky
          .post("/api/data/Percentiles", {
            headers: { Authorization: jwt },
            json: {
              geographyVintage,
              acsYear,
              statistic,
            },
          })
          .json();
        if (!percentileResult.is(fetchedPercentiles)) {
          console.error(statAsString);
          console.error(fetchedPercentiles);
          throw new Error(`!statsResult.is(fetchedPercentiles)`);
        }
        setPercentiles((prevPercentiles) => {
          return {
            ...prevPercentiles,
            [statAsString]: fetchedPercentiles,
          };
        });
      })();
    }
  }, [jwt, acsYear, geographyVintage, statistic, percentiles]);

  useEffect(() => {
    // console.log("doing datalayer effect");
    const uuids = new Set<string>(displayInfo.map((x) => x.jsonUUID));
    const alreadyLoadedUUIDs = new Set<string>(loadingUUIDs);
    const uuidsToLoad = [...uuids].filter(
      (uuid) => !alreadyLoadedUUIDs.has(uuid)
    );
    if (uuidsToLoad.length > 0) {
      // console.log(JSON.stringify(uuidsToLoad));
      (async () => {
        // console.log("Opening cache");
        const myCache = await caches.open("tractasaurus");
        // console.log(`fetching ${[...uuids]}`);
        const fetchedJsons = await Promise.all(
          uuidsToLoad.map(async (uuid) => {
            const jsonFile = await cachedGet(myCache, `/geojson/${uuid}.json`);
            if (!featureMap.is(jsonFile)) {
              throw new Error(`!featureMap.is(/geojson/${uuid}.json)`);
            }
            for (const v of Object.values(jsonFile)) {
              (v.properties as any).uuid = uuid;
            }
            return jsonFile;
          })
        );
        // console.log(`got them ${[...uuids]}`);
        // console.log(fetchedJsons);
        const fetchedJsonMap = Object.fromEntries(
          uuidsToLoad.map((uuid, idx) => [uuid, fetchedJsons[idx]])
        );

        setBoundaries((prevBoundaries) => ({
          ...prevBoundaries,
          ...fetchedJsonMap,
        }));
      })();
      setLoadingUUIDs((prevUUIDs) => [...prevUUIDs, ...uuidsToLoad]);
    }
  }, [displayInfo, boundaries, loadingUUIDs]);

  useEffect(() => {
    const geoIds = new Set<string>(displayInfo.map(({ geoId }) => geoId));
    const statAsString = stringifyStatistic({
      geographyVintage,
      acsYear,
      statistic,
    });
    const loadingGeoIdsForStat = loadingGeoIds[statAsString] ?? [];
    const alreadyLoadedGeoIds = new Set<string>(loadingGeoIdsForStat);
    const geoIdsToLoad = [...geoIds].filter(
      (geoId) => !alreadyLoadedGeoIds.has(geoId)
    );
    if (geoIdsToLoad.length > 0) {
      (async () => {
        console.log(
          `loading stats for ${JSON.stringify(geoIdsToLoad.slice(0, 5))}${
            geoIdsToLoad.length > 5
              ? " ...and " +
                (geoIdsToLoad.length - 5).toLocaleString() +
                " more"
              : ""
          }`
        );
        const fetchedStats = await ky
          .post("/api/data/CensusStatistic", {
            headers: { Authorization: jwt },
            json: {
              geoIds: geoIdsToLoad,
              geographyVintage,
              acsYear,
              statistic,
            },
          })
          .json();
        if (!statsResult.is(fetchedStats)) {
          console.error(geoIdsToLoad);
          console.error(fetchedStats);
          throw new Error(`!statsResult.is(fetchedStats)`);
        }
        setStats((prevStats) => {
          const prevStatsForStatistic = prevStats[statAsString] ?? {};
          return {
            ...prevStats,
            [statAsString]: {
              ...prevStatsForStatistic,
              ...fetchedStats,
            },
          };
        });
      })();
      setLoadingGeoIds((prevGeoIds) => {
        const prevGeoIdsForStat = prevGeoIds[statAsString] ?? [];
        return {
          ...prevGeoIds,
          [statAsString]: [...prevGeoIdsForStat, ...geoIdsToLoad],
        };
      });
    }
  }, [
    displayInfo,
    jwt,
    stats,
    loadingGeoIds,
    geographyVintage,
    acsYear,
    statistic,
  ]);

  // Unnecessary step but let's just go with it for now
  const geoidLines = {
    type: "FeatureCollection",
    features: displayInfo
      .filter(
        ({ geoId, jsonUUID }) => boundaries[jsonUUID]?.[geoId] !== undefined
      )
      .map(({ geoId, jsonUUID }) => boundaries[jsonUUID][geoId]),
  };

  const statAsString = stringifyStatistic(censusStatistic);
  const statsForStatistic = stats[statAsString] ?? {};

  const geoidFill = {
    type: "FeatureCollection",
    features: geoidLines.features
      .filter(
        (x) =>
          statsForStatistic[x.properties.GEOID] !== undefined &&
          statsForStatistic[x.properties.GEOID] > 0
      )
      .map((feature) => ({
        ...feature,
        properties: {
          ...feature.properties,
          originalGeometry: JSON.stringify(feature.geometry),
          stat: statsForStatistic[feature.properties.GEOID],
        },
      })),
  };

  const percentilesForStat = percentiles[statAsString];

  return (
    <>
      <Source type="geojson" data={geoidLines as any}>
        <Layer id={"geoid-lines"} type={"line"} paint={{ "line-width": 1 }} />
      </Source>
      {percentilesForStat && (
        <Source type="geojson" data={geoidFill as any}>
          <Layer
            id={"geoid-fill"}
            type={"fill"}
            paint={{
              "fill-color": {
                property: "stat",
                stops: magmatize(
                  percentilesForStat.tractPercentiles,
                  MAGMA_COLORS
                ),
              },
              "fill-opacity": 0.5,
            }}
          />
        </Source>
      )}
    </>
  );
}

export default React.memo(DataLayer, areEqual);
