import React from "react";
import { Layer, Source } from "react-map-gl";

function areEqual(
  prevProps: HoveredLayerProps,
  nextProps: HoveredLayerProps
): boolean {
  return (
    prevProps.geoId === nextProps.geoId && prevProps.uuid === nextProps.uuid
  );
}

export type HoveredLayerProps = {
  geoId: string;
  uuid: string;
  geometry: string;
};

function HoveredLayer(props: HoveredLayerProps) {
  const { geometry } = props;
  // console.log("ugh fine");
  return (
    <Source
      type="geojson"
      data={{
        type: "Feature",
        properties: {},
        geometry: JSON.parse(geometry) as any,
      }}
    >
      <Layer
        id={"hovered-line"}
        type={"line"}
        paint={{
          "line-width": 2,
          "line-color": "#111111",
          "line-opacity": 1,
        }}
      />
    </Source>
  );
}

export default React.memo(HoveredLayer, areEqual);
