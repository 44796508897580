import ky from "ky";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import MapGL, { MapEvent } from "react-map-gl";
import {
  BoundingBox,
  doBoxesIntersect,
  estimatePercentInViewport,
  mapboxBoundsToBBox,
} from "./BoundingBox";
import DataLayer from "./DataLayer";
import { useThrottle, usePrevious } from "./hooks/useThrottle";
import HoveredLayer from "./HoveredLayer";
import {
  BasicPlusCache,
  BasicPlusResponse,
  GeoJSONDisplayInfo,
  MyViewport,
  getExpandedBounds,
} from "./utils-and-types";

const INITIAL_VIEWPORT = {
  latitude: 33.75,
  longitude: -84.388,
  zoom: 12,
  bearing: 0,
  pitch: 0,
  width: 1,
  height: 1,
};

const INITIAL_CACHE: BasicPlusCache = {
  "*": {
    approximateTractCount: 74000,
    boundingBox: { southLat: -90, westLng: -180, northLat: 90, eastLng: 180 },
    name: "USA",
  },
};

function querySubtreePlus(
  geoId: string,
  cache: BasicPlusCache,
  bounds: BoundingBox,
  maxFIPSLength: number
): { missingChildFIPS: string[]; intersections: string[] } {
  const geoIdBasicInfo = cache[geoId];
  if (geoIdBasicInfo === undefined) {
    // Shouldn't ever happen - if we know about the child FIPS code,
    // there should be entries in the cache
    console.error(`querySubtreePlus: cache[${geoId}] === undefined`);
    throw new Error(`querySubtreePlus: cache[${geoId}] === undefined`);
  }

  if (doBoxesIntersect(geoIdBasicInfo.boundingBox, bounds)) {
    const children =
      geoId.length >= maxFIPSLength ? [] : geoIdBasicInfo.children;
    if (children === undefined) {
      return {
        missingChildFIPS: [geoId],
        intersections: [geoId],
      };
    } else {
      const childResults = children.map((child) =>
        querySubtreePlus(child, cache, bounds, maxFIPSLength)
      );
      return {
        missingChildFIPS: childResults.flatMap(
          (child) => child.missingChildFIPS
        ),
        intersections: [
          geoId,
          ...childResults.flatMap((child) => child.intersections),
        ],
      };
    }
  } else {
    return { missingChildFIPS: [], intersections: [] };
  }
}

function computeGeoIdsToRequest(
  missingGeoIds: string[],
  alreadyRequestedGeoIds: string[]
): string[] {
  // Returns the elements from first argument that are not in second argument

  const requestedSet = new Set(alreadyRequestedGeoIds);
  return missingGeoIds.filter((s) => !requestedSet.has(s));
}

function computeNewRequestedGeoIds(
  previouslyRequested: string[],
  newlyRequested: string[],
  newlyReceived: string[]
): string[] {
  const stillUnreceived = computeGeoIdsToRequest(
    previouslyRequested,
    newlyReceived
  );
  const realNewlyRequested = computeGeoIdsToRequest(
    newlyRequested,
    previouslyRequested
  );
  if (
    realNewlyRequested.length === 0 &&
    stillUnreceived.length === previouslyRequested.length
  ) {
    return previouslyRequested;
  } else {
    // console.log("New list of geoIds:");
    // console.log({ previouslyRequested, newlyRequested, newlyReceived });
    // console.log({ stillUnreceived, realNewlyRequested });
    return [...stillUnreceived, ...realNewlyRequested];
  }
}

function computeNewPlusCache(
  prevCache: BasicPlusCache,
  newInfo: BasicPlusResponse
): BasicPlusCache {
  // We're getting two different kinds of inforation here:
  // (1) The list of children of the top-level keys
  // (2) The basic info of each child

  const newParentEntries: BasicPlusCache = {};
  const newChildEntries: BasicPlusCache = {};
  for (const [parentFIPS, childrenBasicInfo] of Object.entries(newInfo)) {
    const children = Object.keys(childrenBasicInfo);
    const prevEntry = prevCache[parentFIPS];
    if (prevEntry === undefined) {
      // Shouldn't ever happen - if we know about the child FIPS code,
      // there should be entries in the cache
      console.error(
        `computeNewPlusCache: prevCache[${parentFIPS}] === undefined`
      );
      throw new Error(
        `computeNewPlusCache: prevCache[${parentFIPS}] === undefined`
      );
    }
    newParentEntries[parentFIPS] = { ...prevEntry, children };
    for (const [childFIPS, basicInfo] of Object.entries(childrenBasicInfo)) {
      newChildEntries[childFIPS] = basicInfo;
    }
  }

  return {
    ...prevCache,
    ...newParentEntries,
    ...newChildEntries,
  };
}

function computeVisibleGeographies(intersections: string[]): {
  states: string[];
  counties: string[];
  trueCounties: string[];
  tracts: string[];
} {
  const tracts = intersections.filter((code) => code.length === 11);
  const counties = intersections.filter((code) => code.length === 5);

  const states: Set<string> = new Set(
    counties.map((county) => county.substring(0, 2))
  );
  const trueCounties: Set<string> = new Set(
    tracts.map((tracts) => tracts.substring(0, 5))
  );

  return {
    tracts: Array.from(tracts),
    counties: Array.from(counties),
    trueCounties: Array.from(trueCounties),
    states: Array.from(states),
  };
}

function incomingPlusInfo(
  newInfo: BasicPlusResponse,
  setPlusCache: React.Dispatch<React.SetStateAction<BasicPlusCache>>,
  setPreviouslyRequestedNames: React.Dispatch<React.SetStateAction<string[]>>
) {
  setPlusCache((prevCache) => computeNewPlusCache(prevCache, newInfo));
  setPreviouslyRequestedNames((prevRequested) =>
    computeNewRequestedGeoIds(prevRequested, [], [])
  );
}

function fetchPlusInfo(
  jwt: string,
  geoIdsToRequest: string[],
  setPlusCache: React.Dispatch<React.SetStateAction<BasicPlusCache>>,
  setPreviouslyRequestedNames: React.Dispatch<React.SetStateAction<string[]>>
) {
  if (geoIdsToRequest.length > 0) {
    (async () => {
      // console.log(`Getting plus info for: [${geoIdsToRequest.join(", ")}]`);
      const tracts = geoIdsToRequest.filter((x) => x.length > 5);
      const biggerThanTracts = geoIdsToRequest.filter((x) => x.length <= 5);
      const autogenResponse: BasicPlusResponse = {};
      if (tracts.length > 0) {
        // console.log(`Skipping ${tracts.join(", ")}`);
        for (const tract of tracts) {
          autogenResponse[tract] = {};
        }
        incomingPlusInfo(
          autogenResponse,
          setPlusCache,
          setPreviouslyRequestedNames
        );
      }
      if (biggerThanTracts.length > 0) {
        console.log(`Fetching info for ${biggerThanTracts.join(", ")}`);
        const plusInfo = (await ky
          .post("/api/data/BasicPlus", {
            headers: { Authorization: jwt },
            json: { geoIds: biggerThanTracts },
          })
          .json()) as BasicPlusResponse;
        // console.log(plusInfo);
        incomingPlusInfo(plusInfo, setPlusCache, setPreviouslyRequestedNames);
      }
    })();
  }
}

function getMissingFIPSAndIntersections(
  viewport: MyViewport,
  cache: BasicPlusCache
): { missingChildFIPS: string[]; intersections: string[] } {
  const { expandedBounds } = getExpandedBounds(viewport);
  return querySubtreePlus(
    "*",
    cache,
    mapboxBoundsToBBox(expandedBounds),
    viewport.zoom < 7.5 ? 5 : 11
  );
}

function fetchRequiredInfoIntoCache(
  jwt: string,
  viewport: MyViewport,
  plusCache: BasicPlusCache,
  setPlusCache: React.Dispatch<React.SetStateAction<BasicPlusCache>>,
  previouslyRequestedGeoIds: string[],
  setPreviouslyRequestedGeoIds: React.Dispatch<React.SetStateAction<string[]>>
) {
  const { missingChildFIPS } = getMissingFIPSAndIntersections(
    viewport,
    plusCache
  );

  const geoIdsToRequest = computeGeoIdsToRequest(
    missingChildFIPS,
    previouslyRequestedGeoIds
  );

  if (geoIdsToRequest.length > 0) {
    fetchPlusInfo(
      jwt,
      geoIdsToRequest,
      setPlusCache,
      setPreviouslyRequestedGeoIds
    );
    setPreviouslyRequestedGeoIds((prevState) =>
      computeNewRequestedGeoIds(prevState, geoIdsToRequest, [])
    );
  }
}

function getStuffToShow(
  viewport: MyViewport,
  cache: BasicPlusCache,
  displayTracts: boolean
) {
  const { intersections } = getMissingFIPSAndIntersections(viewport, cache);
  const { counties, trueCounties, tracts } =
    computeVisibleGeographies(intersections);
  const displayInfo: GeoJSONDisplayInfo = [];

  if (trueCounties.length === 0) {
    for (const countyFIPS of counties) {
      const geoJsonUUIDs = cache[countyFIPS]?.geoJsonUUIDs;
      if (geoJsonUUIDs !== undefined) {
        displayInfo.push({
          geoId: countyFIPS,
          jsonUUID: geoJsonUUIDs[0],
        });
      }
    }
  } else {
    if (displayTracts) {
      for (const tractFIPS of tracts) {
        const geoJsonUUIDs = cache[tractFIPS]?.geoJsonUUIDs;
        if (geoJsonUUIDs !== undefined) {
          displayInfo.push({
            geoId: tractFIPS,
            jsonUUID: geoJsonUUIDs[1],
          });
        }
      }
    } else {
      for (const countyFIPS of trueCounties) {
        const geoJsonUUIDs = cache[countyFIPS]?.geoJsonUUIDs;
        if (geoJsonUUIDs !== undefined) {
          displayInfo.push({
            geoId: countyFIPS,
            jsonUUID: geoJsonUUIDs[1],
          });
        }
      }
    }
  }
  // const visibleStats: unknown[] = [];
  // const startTime = Date.now();
  const visibleStatsUnsorted = counties.map((countyFIPS) => {
    const stateFIPS = countyFIPS.substring(0, 2);
    const stateName = cache[stateFIPS]?.name ?? stateFIPS;
    const countyName = cache[countyFIPS]?.name ?? countyFIPS;
    const tractCount = intersections.filter(
      (geoid) => geoid.length === 11 && geoid.substring(0, 5) === countyFIPS
    ).length;

    return {
      countyFIPS,
      tractCount,
      description: `${countyName} County, ${stateName} (${tractCount})`,
    };
  });

  const visibleStats = {
    top10: [...visibleStatsUnsorted]
      .sort((a, b) => b.tractCount - a.tractCount)
      .slice(0, 10),
    additionalInfo:
      visibleStatsUnsorted.length > 10
        ? `...and ${(visibleStatsUnsorted.length - 10).toLocaleString()} more`
        : null,
  };

  // const endTime = Date.now();
  // console.log(`${endTime - startTime} ms`);

  return {
    displayInfo,
    visibleStats,
  };
}

function getEstimatedTractCountForGeoid(
  viewportBBox: BoundingBox,
  geoId: string,
  cache: BasicPlusCache
): number {
  const basicInfo = cache[geoId];
  const bbox = basicInfo?.boundingBox;
  if (basicInfo === undefined || bbox === undefined) {
    throw new Error(
      `cache[${geoId}]?.boundingBox === undefined - should be impossible`
    );
  }
  if (!doBoxesIntersect(viewportBBox, bbox)) {
    return 0;
  }
  const overlap = estimatePercentInViewport(viewportBBox, bbox);
  if (overlap >= 0.999) {
    return basicInfo.approximateTractCount;
  }
  if (basicInfo.children === undefined) {
    return overlap * basicInfo.approximateTractCount;
  }
  return basicInfo.children
    .map((childId) =>
      getEstimatedTractCountForGeoid(viewportBBox, childId, cache)
    )
    .reduce((x, y) => x + y, 0);
}

function getEstimatedTractCount(viewport: MyViewport, cache: BasicPlusCache) {
  const { expandedBounds } = getExpandedBounds(viewport);

  return getEstimatedTractCountForGeoid(
    mapboxBoundsToBBox(expandedBounds),
    "*",
    cache
  );
}

function getShouldDisplayTracts(
  prevZoom: unknown,
  newZoom: number,
  viewport: MyViewport,
  cache: BasicPlusCache
): boolean | null {
  // How should this work?
  //
  // (1) [displayTracts] = useState(true)
  // (2) prevZoom = usePrevious(zoom)
  // (3) If zoom > prevZoom and zoom > 9 and estimatedTractCount < X,    setDisplayTracts(true)
  // (4) if (zoom < 9) or (zoom < prevZoom and estimatedTractCount > 1.5X), setDisplayTracts(false)

  if (typeof prevZoom !== "number") {
    // console.log("prevZoom !== number, returning null");
    return null;
  }
  if (newZoom < 8.5) {
    // console.log(`false: ${JSON.stringify({ newZoom, prevZoom })}`);
    return false;
  }
  if (newZoom === prevZoom) {
    return null;
  }
  const estimatedTractCount = getEstimatedTractCount(viewport, cache);
  if (newZoom > prevZoom && newZoom > 9 && estimatedTractCount < 2000) {
    // console.log(
    //   `true: ${JSON.stringify({ newZoom, prevZoom, estimatedTractCount })}`
    // );
    return true;
  } else if (newZoom < prevZoom && estimatedTractCount > 3000) {
    // console.log(
    //   `false: ${JSON.stringify({ newZoom, prevZoom, estimatedTractCount })}`
    // );
    return false;
  }
  return null;
}

function getFriendlyTractName(geoId: string, cache: BasicPlusCache): string {
  const stateFIPS = geoId.substring(0, 2);
  const countyFIPS = geoId.substring(0, 5);

  const stateName = cache[stateFIPS]?.name ?? stateFIPS;
  const countyName = cache[countyFIPS]?.name ?? countyFIPS.substring(2, 5);
  const tractName = `${geoId.substring(5, 9)}.${geoId.substring(9, 11)}`;

  return `Tract ${tractName}, ${countyName} County, ${stateName}`;
}

function getFriendlyCountyName(geoId: string, cache: BasicPlusCache): string {
  const stateFIPS = geoId.substring(0, 2);
  const countyFIPS = geoId.substring(0, 5);

  const stateName = cache[stateFIPS]?.name ?? stateFIPS;
  const countyName = cache[countyFIPS]?.name ?? countyFIPS.substring(2, 5);

  return `${countyName} County, ${stateName}`;
}

function getFriendlyStateName(geoId: string, cache: BasicPlusCache): string {
  const stateFIPS = geoId.substring(0, 2);
  const stateName = cache[stateFIPS]?.name ?? stateFIPS;

  return `${stateName}`;
}

function getFriendlyName(geoId: string, cache: BasicPlusCache): string {
  switch (geoId.length) {
    case 2:
      return getFriendlyStateName(geoId, cache);
    case 5:
      return getFriendlyCountyName(geoId, cache);
    case 11:
      return getFriendlyTractName(geoId, cache);
    default:
      return `${geoId} ain't no place`;
  }
}

export type MainPageProps = {
  jwt: string;
  mapboxToken: string;
  isStatsLoader: boolean;
  initialLat: number;
  initialLon: number;
  modalize: (lat: number, lon: number) => void;
};

function MainPage(props: MainPageProps) {
  const { jwt, mapboxToken, isStatsLoader, initialLat, initialLon, modalize } =
    props;
  const [prevRequestedGeoIds, setPrevRequestedGeoIds] = useState(
    [] as string[]
  );
  const [viewport, setViewport] = useState({
    ...INITIAL_VIEWPORT,
    latitude: initialLat,
    longitude: initialLon,
  });
  const [plusCache, setPlusCache] = useState(INITIAL_CACHE);
  const [displayTracts, setDisplayTracts] = useState(true);
  const [hovered, setHovered] = useState({
    geoId: null,
    val: null,
    uuid: null,
    geometry: null,
  } as {
    geoId: string | null;
    uuid: string | null;
    val: number | null;
    geometry: unknown;
  });

  const throttledViewport = useThrottle(viewport, 100);

  useEffect(() => {
    fetchRequiredInfoIntoCache(
      jwt,
      throttledViewport,
      plusCache,
      setPlusCache,
      prevRequestedGeoIds,
      setPrevRequestedGeoIds
    );
  }, [jwt, plusCache, throttledViewport, prevRequestedGeoIds]);

  const prevZoom = usePrevious(throttledViewport.zoom, [throttledViewport]);

  useEffect(() => {
    const shouldDisplayTracts = getShouldDisplayTracts(
      prevZoom,
      throttledViewport.zoom,
      throttledViewport,
      plusCache
    );
    if (shouldDisplayTracts !== null && shouldDisplayTracts !== displayTracts) {
      // console.log(
      //   `changing displayTracts from ${displayTracts} to ${shouldDisplayTracts}`
      // );
      setDisplayTracts(shouldDisplayTracts);
    } else {
      // console.log(JSON.stringify({ shouldDisplayTracts, displayTracts }));
    }
  }, [displayTracts, prevZoom, throttledViewport, plusCache]);

  const { displayInfo } = useMemo(
    () => getStuffToShow(throttledViewport, plusCache, displayTracts),
    [throttledViewport, plusCache, displayTracts]
  );

  const geoInfo =
    hovered.geoId !== null && hovered.val !== null ? (
      <>
        <p>{getFriendlyName(hovered.geoId, plusCache)}</p>
        <p>Median Household Income: ${hovered.val.toLocaleString()}</p>
      </>
    ) : (
      <></>
    );

  const onHover = useCallback(
    (event: MapEvent) => {
      const { features } = event;
      const feature = features && features[0];
      if (feature && feature.layer?.id === "geoid-fill") {
        if (
          !hovered ||
          hovered.geoId !== feature.properties.GEOID ||
          hovered.uuid !== feature.properties.uuid
        ) {
          setHovered({
            geoId: feature.properties.GEOID,
            uuid: feature.properties.uuid,
            val: feature.properties.stat,
            geometry: feature.properties.originalGeometry,
          });
        }
      }
    },
    [hovered]
  );

  const [isInfoExpanded, setIsInfoExpanded] = useState(false);

  return (
    <div className="MainPage">
      <div className="main-map">
        <MapGL
          {...viewport}
          width="100%"
          height="100%"
          mapStyle="mapbox://styles/mapbox/streets-v11"
          minZoom={2}
          onViewportChange={setViewport}
          mapboxApiAccessToken={mapboxToken}
          onHover={onHover}
        >
          <DataLayer
            jwt={jwt}
            displayInfo={displayInfo}
            censusStatistic={{
              geographyVintage: 2010,
              acsYear: 2014,
              statistic: "B19013_001E",
            }}
          />
          {hovered.geoId &&
            hovered.geometry &&
            hovered.uuid &&
            typeof hovered.geometry === "string" && (
              <HoveredLayer
                geoId={hovered.geoId}
                uuid={hovered.uuid}
                geometry={hovered.geometry}
              />
            )}
        </MapGL>
      </div>
      <div
        className={`info ${
          isInfoExpanded ? "info-expanded" : "info-collapsed"
        }`}
      >
        {isStatsLoader && (
          <div>
            <button
              onClick={() => modalize(viewport.latitude, viewport.longitude)}
            >
              Edit some statistics
            </button>
          </div>
        )}
        <div className="expand-info">
          <button
            onClick={() => {
              setIsInfoExpanded((prevValue) => !prevValue);
            }}
          >
            {isInfoExpanded ? "Collapse" : "Expand"}
          </button>
        </div>
        <div className="geo-info">{geoInfo}</div>
      </div>
    </div>
  );
}

export default MainPage;
