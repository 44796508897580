import React, { useEffect, useState } from "react";
import "./App.css";
import "mapbox-gl/dist/mapbox-gl.css";
import ky from "ky";
import { Auth } from "@aws-amplify/auth";
import awsconfig from "./aws-exports";
import * as t from "io-ts";
import MainPage from "./MainPage";
import EditPage from "./EditPage";
import { useCallback } from "react";

Auth.configure(awsconfig);

const mapboxResponseType = t.type({
  mapboxToken: t.string,
  lat: t.number,
  lon: t.number,
});

function App() {
  const [loginState, setLoginState] = useState(
    "UNKNOWN" as "UNKNOWN" | "NOT_LOGGED_IN" | "LOGGED_IN"
  );
  const [jwt, setJwt] = useState(null as string | null);
  const [mapboxToken, setMapboxToken] = useState(null as string | null);
  const [groups, setGroups] = useState([] as string[]);
  // const [counties, setCounties] = useState([] as string[]);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [initialPos, setInitialPos] = useState(
    null as { lat: number; lon: number } | null
  );
  const [displayState, setDisplayState] = useState("MAP" as "MAP" | "EDIT");

  async function attemptLogin() {
    try {
      const session = await Auth.currentSession();
      const accessToken = session.getAccessToken();
      const newJWT = accessToken.getJwtToken();
      setJwt(newJWT);
      setGroups(accessToken.payload["cognito:groups"] ?? []);
      setLoginState("LOGGED_IN");
    } catch (err) {
      console.log(err);
      setLoginState("NOT_LOGGED_IN");
      setJwt(null);
    }
  }

  async function getMapboxToken(jwt: string) {
    const mapboxTokenResponse = await ky
      .post("/api/data/MapboxToken", {
        headers: { Authorization: jwt },
        json: {},
      })
      .json();
    if (!mapboxResponseType.is(mapboxTokenResponse)) {
      console.error(`Invalid mapbox token response: ${mapboxTokenResponse}`);
      return;
    }
    const { mapboxToken, lat, lon } = mapboxTokenResponse;
    setMapboxToken(mapboxToken);
    setInitialPos({ lat, lon });
  }

  useEffect(() => {
    attemptLogin();
  }, []);
  useEffect(() => {
    if (jwt !== null && mapboxToken === null) {
      getMapboxToken(jwt);
    }
  }, [jwt, mapboxToken]);

  const modalize = useCallback((lat: number, lon: number) => {
    setDisplayState("EDIT");
    setInitialPos({ lat, lon });
  }, []);

  const unmodalize = useCallback(() => {
    setDisplayState("MAP");
  }, []);

  switch (loginState) {
    case "UNKNOWN":
      return <div className="App">Logging in...</div>;
    case "NOT_LOGGED_IN":
      return (
        <div className="App">
          <p>
            <label htmlFor="username">Email</label>
            <input
              type="text"
              id="username"
              value={username}
              onChange={(e) => {
                setUsername(e.target.value);
              }}
            ></input>
          </p>
          <p>
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            ></input>
          </p>
          <button
            onClick={async () => {
              try {
                await Auth.signIn(username, password);
                attemptLogin();
              } catch (e) {
                console.log(JSON.stringify(e));
                console.error(e);
              }
            }}
          >
            Log In
          </button>
        </div>
      );
    case "LOGGED_IN":
      if (mapboxToken === null || jwt === null || initialPos === null) {
        return <div className="App">Logging in...</div>;
      } else {
        switch (displayState) {
          case "MAP":
            return (
              <div className="App">
                <MainPage
                  mapboxToken={mapboxToken}
                  jwt={jwt}
                  isStatsLoader={groups.includes("statsloaders")}
                  initialLat={initialPos.lat}
                  initialLon={initialPos.lon}
                  modalize={modalize}
                />
              </div>
            );
          case "EDIT":
            return (
              <div className="App">
                <EditPage
                  jwt={jwt}
                  isStatsLoader={groups.includes("statsloaders")}
                  unmodalize={unmodalize}
                />
              </div>
            );
          default:
            return <div className="App">How did this happen?</div>;
        }
      }
    default:
      return <div className="App">How did this happen?</div>;
  }
}

export default App;
