import { WebMercatorViewport } from "react-map-gl";
import { BoundingBox } from "./BoundingBox";

export type MapboxBounds = number[][];
type BasicPlusInfo = {
  name?: string;
  geoJsonUUIDs?: [string, string];
  approximateTractCount: number;
  boundingBox: BoundingBox;
  children?: string[];
};
type ChildrenBasicInfo = {
  [childFIPS: string]: BasicPlusInfo;
};
export interface MyViewport {
  latitude: number;
  longitude: number;
  zoom: number;
  bearing: number;
  pitch: number;
  width: number;
  height: number;
}
export type BasicPlusResponse = {
  [parentFIPS: string]: ChildrenBasicInfo;
};
export type BasicPlusCache = Partial<Record<string, BasicPlusInfo>>;
export type GeoJSONDisplayInfo = {
  geoId: string;
  jsonUUID: string;
}[];

export function eqSet<T>(set1: Set<T>, set2: Set<T>) {
  if (set1.size !== set2.size) return false;
  for (const memb of set1) {
    if (!set2.has(memb)) {
      return false;
    }
  }
  return true;
}

export function getExpandedBounds(viewport: MyViewport) {
  const wmv = new WebMercatorViewport(viewport);

  // Per https://visgl.github.io/react-map-gl/docs/api-reference/web-mercator-viewport:
  // Returns [[lon, lat], [lon, lat]] as the south west and north east corners
  // of the smallest orthogonal bounds that encompasses the visible region.
  const bounds: MapboxBounds = wmv.getBounds();
  const [[westLon, southLat], [eastLon, northLat]] = bounds;
  const lonWidth = eastLon - westLon;
  const latHeight = northLat - southLat;
  const centerLon = (eastLon + westLon) / 2;
  const centerLat = (northLat + southLat) / 2;

  const expandedWidth = lonWidth * 1.5;
  const expandedHeight = latHeight * 1.5;

  const expandedBounds: MapboxBounds = [
    [centerLon - expandedWidth / 2, centerLat - expandedHeight / 2],
    [centerLon + expandedWidth / 2, centerLat + expandedHeight / 2],
  ];

  return { bounds, expandedBounds };
}
